<template>
  <div>
    <input
      class="hidden"
      :accept="accept"
      :id="id"
      :multiple="multiple"
      type="file"
      @change="onUpload($event)"
    />
    <form-button
      :command="() => openFileDialog()"
      :disabled="disabled"
      :id="`${id}-button`"
      :label="label"
      :textVariant="textVariant"
      type="button"
      :variant="variant"
    />
    <div v-if="filename" class="mt-2 flex items-center gap-1">
      <CheckCircleIcon class="h-6 w-6 text-green-600" />{{ filename }}
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/24/outline";
import { ref } from "vue";
import FormButton from "./FormButton.vue";
export default {
  components: { CheckCircleIcon, FormButton },
  props: {
    accept: {
      required: true,
      type: String,
    },
    disabled: {
      required: true,
      type: Boolean,
    },
    id: {
      required: false,
      type: String,
    },
    invalid: {
      default: false,
      required: false,
      type: Boolean,
    },
    label: {
      default: "choose_file",
      type: String,
    },
    modelValue: {
      type: Object,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    textVariant: {
      default: "light",
      type: String,
    },
    variant: {
      default: "indigo",
      type: [Object, String],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const openFileDialog = () => {
      document.getElementById(props.id).click();
    };

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };

    const filename = ref("");

    const onUpload = (event) => {
      const file = event.target.files[0];
      filename.value = file.name;
      getBase64(file).then((data) => {
        const fileData = {
          name: filename.value,
          file: data,
        };
        emit("update:modelValue", fileData);
      });
    };

    return {
      filename,
      onUpload,
      openFileDialog,
    };
  },
};
</script>
