<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.profile") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card p-4">
      <div
        class="grid grid-cols-1 gap-x-12 gap-y-4 p-4 sm:grid-cols-3 md:grid-cols-2 lg:grid-cols-4"
      >
        <div class="flex flex-col items-center md:col-span-2 lg:col-span-1">
          <div
            v-if="waiting"
            class="aspect-square flex w-80 max-w-full animate-pulse items-center justify-center rounded-full bg-slate-300"
          />
          <img
            v-else-if="user.avatar"
            :class="
              user.avatar
                ? 'aspect-square w-80 max-w-full rounded-full object-cover'
                : 'w-80 max-w-full rounded-full object-cover'
            "
            :src="getAvatar(user)"
          />
          <div
            v-else
            class="aspect-square flex w-80 max-w-full items-center justify-center rounded-full bg-slate-300"
          >
            <UserIcon class="h-3/4 w-3/4 text-white" />
          </div>
          <div class="mt-4 flex justify-center">
            <form-filepicker
              accept="image/jpeg, image/png"
              :disabled="waiting"
              id="profile-image-upload"
              label="change_avatar"
              :multiple="false"
              :textVariant="company.primaryText || 'light'"
              :variant="company.primary || 'indigo'"
              v-model="file"
            />
          </div>
        </div>
        <div class="flex flex-col gap-y-4 sm:py-4 xl:justify-center">
          <div>
            <label class="block">{{ t("labels.first_name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              user.updatedValues?.firstName || user.firstName
            }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.last_name") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{
              user.updatedValues?.lastName || user.lastName
            }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.language") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ getLanguage(user.language) }}</span>
          </div>
        </div>
        <div
          class="flex flex-col gap-y-4 sm:py-4 xl:col-span-2 xl:justify-center"
        >
          <div>
            <label class="block">{{ t("labels.email") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.email }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.phone") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else>{{ user.updatedValues?.phone || user.phone }}</span>
          </div>
          <div>
            <label class="block">{{ t("labels.address") }}</label>
            <span v-if="waiting">
              <skeleton class="h-5 w-full" />
            </span>
            <span v-else
              >{{ user.updatedValues?.address || user.address }},
              {{ user.updatedValues?.postalCode || user.postalCode }}
              {{ user.updatedValues?.city || user.city }}</span
            >
          </div>
        </div>
      </div>
      <hr class="my-4 border-slate-300" />
      <div class="flex justify-end">
        <form-button
          :command="() => router.push('/profile/edit')"
          :disabled="waiting"
          id="profile-edit-button"
          label="edit"
          :textVariant="company.primaryText || 'light'"
          type="button"
          :variant="company.primary || 'indigo'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { UserIcon } from "@heroicons/vue/24/outline";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import FormFilepicker from "../components/form/FormFilepicker.vue";
import Skeleton from "../components/loading/Skeleton.vue";
import { getAvatar } from "../utils/imageUtils";
import { getLanguage } from "../utils/languageUtils";
export default {
  components: { Breadcrumb, FormButton, FormFilepicker, Skeleton, UserIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();

    const company = computed(() => store.state.company.company);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.company.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    const file = ref(null);

    watch(
      () => file.value,
      (file) => {
        if (file) {
          store.dispatch("authentication/updateAvatar", file);
        }
      }
    );

    return {
      company,
      file,
      getAvatar,
      getLanguage,
      router,
      t,
      user,
      waiting,
    };
  },
};
</script>
